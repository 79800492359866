import { useState, memo, useRef, useEffect, Fragment } from "react";

import cookie from "react-cookies";
import { toast } from "react-toastify";
import { Menu } from "react-contexify";
import axios from "axios";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Checkout from "../Components/Checkout";
import ContextMenu from "../Components/ContextMenu";
import UploadButton from "../Components/UploadButton";

import { UsePics } from "../State/AppContext";
import { AvailableOptions } from "../Constants";
import CanvasBorderLess3d from "../Components/CanvasBorderLess3d";

const SelectPhoto = () => {
  const {
    setDeleteCropImage,
    deleteCropImage,
    setPartiCulerData,
    particulerEditData,
    setParticulerEditData,
    croppedData,
    setCroppedData,
    getButtonName,
    setGetButton,
    setImageMmSize,
    setUploadResponse,
    uploadResponse,
    uploadResDltIndex,
    setUploadResultDltIndex,
    uploadCropIndex,
    setUploadCropIndex,
    uploadAllRes,
    setUploadAllRes,
    setUploadLoader,
    setUploadApiHandling,
  } = UsePics();

  // if (uploadResDltIndex && uploadResponse.length > croppedData.length) {
  //   let newData = uploadResponse.filter(
  //     (_, index) => index !== uploadResDltIndex
  //   );
  //   setUploadResponse(newData);
  // }

  const pgFilterRef = useRef(null);
  const frameFilterRef = useRef(null);
  const [cropModal, setCropmodal] = useState(false);
  const [cropAndDeleteToggle, setCropAndDeleteToggle] = useState(false);
  const [selectPhoto, setSelectPhoto] = useState({
    uploadedPhotos: [],
    availableStyles: AvailableOptions,
    activeStyle: AvailableOptions[0],
    showCropperModal: false,
    imageForCrop: null,
    showAlertForPreview: false,
    showMenuPopOver: false,
    showCheckout: false,
    haveCheckForCookies: false,
  });
  const [framAndBorder, setFramAndBorder] = useState("");

  const removePhoto = () => {
    let newPhotos = selectPhoto?.uploadedPhotos?.filter(
      (p) => p.imgid !== selectPhoto.imageForCrop.imgid
    );
    setSelectPhoto({ ...selectPhoto, uploadedPhotos: newPhotos });
    cookie.remove(`image_${selectPhoto?.imageForCrop?.imgid}`, { path: "/" });
  };

  const GetAvailablePreviews = (photos = []) => {
    let previews = photos?.map((photo) => (
      <Menu
        key={`Preview_${photo?.imgid}`}
        id="menu_id"
        animation="flip"
        event="onClick"
      >
        <div
          key={`Preview_${photo?.imgid}`}
          className="previewImage card"
          onClick={() =>
            setSelectPhoto({ ...selectPhoto, imageForCrop: photo })
          }
        >
          <img src={selectPhoto?.activeStyle?.img} className="first" alt="" />
          <img
            src={photo?.dataUrl}
            className={
              selectPhoto?.activeStyle?.name === "Classic" ||
              selectPhoto?.activeStyle?.name === "Even"
                ? "secondMount"
                : "second"
            }
            alt=""
          />
        </div>
      </Menu>
    ));
    return previews;
  };

  const GetAvailableStyles = () => {
    return selectPhoto?.availableStyles?.map((style) => (
      <div
        className={
          "imageStyleDiv" + selectPhoto?.activeStyle === style?.name
            ? " lightblue"
            : ""
        }
        key={style?.name}
        onClick={() => {
          setGetButton(style?.name);
          setImageMmSize(style?.mm);
          setUploadApiHandling(false);
        }}
      >
        <button
          className={`card ${getButtonName === style?.name ? `active` : ``}`}
          onClick={() => setSelectPhoto({ ...selectPhoto, activeStyle: style })}
        >
          <img className="imageStyle" alt="" src={style?.img} />
          <div className="imageText">{style?.name?.replace("-", " ")}</div>
        </button>
      </div>
    ));
  };

  const handleSendData = (data) => {
    setPartiCulerData(data);
    setDeleteCropImage(data.id);
  };

  const imageDeleteConfirm = (id) => {
    setUploadApiHandling(false);
    // setUploadResultDltIndex(ind);
    // handling delete state response
    const filterData =
      croppedData && croppedData?.filter((data) => data.id !== id);
    setCroppedData(filterData);

    // handling delete upload API response
    // const filterUploadData =
    //   uploadResponse && uploadResponse?.filter((_, index) => index !== ind);
    // setUploadResponse(filterUploadData);
    // toast.success("Image has been successfully deleted.");
  };

  const handleCropButton = async (ele) => {
    setUploadApiHandling(false);
    // setUploadCropIndex(index);
    setCropmodal(true);
    setParticulerEditData(ele);
    setFramAndBorder({
      selectPhoto: selectPhoto.activeStyle.name,
    });
  };

  useEffect(() => {
    const pgFilterDiv = pgFilterRef.current;
    const handleWheel = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
      }
      pgFilterDiv.scrollLeft += event.deltaY;
    };
    pgFilterDiv.addEventListener("wheel", handleWheel);
    return () => {
      pgFilterDiv.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    const pgFilterDiv = frameFilterRef.current;
    const handleWheel = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
      }
      pgFilterDiv.scrollLeft += event.deltaY;
    };
    pgFilterDiv.addEventListener("wheel", handleWheel);
    return () => {
      pgFilterDiv.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    // Track the pageview event when the home page component mounts
    window.gtag('event', 'UploadImagePageLand', {
      'event_category': 'engagement', // You can customize the event category if needed
      'event_label': 'Home Page', // Optional label to provide more context
    });
  }, []);

  const handleImageUpload = () => {
    // Send custom event to Google Analytics
    window.gtag("event", "NumberOfImagesUpload", {
      event_category: "Upload",
      event_label: "Number of Images Uploaded",
      value: croppedData?.length,
    });
  };

  useEffect(() => {
    handleImageUpload();
  }, [croppedData?.length]);

  return (
    <div className="pageCard">
      <Header />
      <div className="scrollmenu" ref={frameFilterRef}>
        <div className="styleHolder">{GetAvailableStyles()}</div>
      </div>
      <div
        className={`scrollmenuPreview select-photo ${
          croppedData.length > 0 ? `home-loader` : ``
        }`}
      >
        <div className="scroll_prev_innr">
          {!croppedData?.length > 0 && (
            <h5 className="gray">Pick Some Photos! </h5>
          )}
          <div className="previewContainer">
            <div
              className={`fram-main-box ${
                selectPhoto.activeStyle.name === `Canvas` ? `canvas-head` : ``
              }`}
              ref={pgFilterRef}
              style={{
                overflowX: "scroll",
                whiteSpace: "nowrap",
                width: "100%",
                height: "100%",
              }}
            >
              {croppedData?.length > 0 &&
                croppedData?.map((ele, index) => {
                  return (
                    <div
                      className={`frame common-fram ${
                        selectPhoto.activeStyle.name
                      } black-frame ${ele?.color ? ele?.color : ""}`}
                      key={index}
                      onClick={() => {
                        handleSendData(ele);
                        setCropAndDeleteToggle(!cropAndDeleteToggle);
                      }}
                    >
                      <img src={ele.dataUrl} alt="show_image" />
                      {selectPhoto.activeStyle.name === `Canvas` ? (
                        <Fragment>
                          {ele?.isCropped ? (
                            <CanvasBorderLess3d data={ele?.dataUrl} />
                          ) : (
                            <CanvasBorderLess3d data={ele?.cropResult} />
                          )}
                        </Fragment>
                      ) : (
                        ""
                      )}
                      {ele.id === deleteCropImage && cropAndDeleteToggle && (
                        <Fragment>
                          <div className="action-buttons">
                            <button onClick={() => handleCropButton(ele)}>
                              <i className="fa fa-crop"></i>
                              Crop
                            </button>
                            <button onClick={() => imageDeleteConfirm(ele.id)}>
                              <i className="fa fa-trash"></i>
                              Delete
                            </button>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  );
                })}
            </div>
            {GetAvailablePreviews(selectPhoto?.uploadedPhotos)}
            <ContextMenu
              id="menu_id"
              removePhoto={() => removePhoto()}
              cropAction={() =>
                setSelectPhoto({ ...selectPhoto, showCropperModal: true })
              }
            />
            <div className="vcentre">
              <UploadButton
                cropModal={cropModal}
                setCropmodal={setCropmodal}
                framAndBorder={framAndBorder}
                particulerEditData={particulerEditData}
              />
            </div>
          </div>
        </div>
      </div>

      <Checkout
        uploadedPhotos={selectPhoto?.uploadedPhotos}
        activeStyle={selectPhoto.activeStyle}
      />

      {croppedData?.length > 0 && <Footer label="Checkout" />}
    </div>
  );
};

export default memo(SelectPhoto);
