import { createContext, useContext, useState } from "react";

export const AppContext = createContext(null);

export const Provider = ({ children }) => {

    const [cropModal, setCropModal] = useState(false);
    const [croppedData, setCroppedData] = useState([]);
    const [getButtonName, setGetButton] = useState("White-Mount");
    const [partiCulerData, setPartiCulerData] = useState([]);
    const [deleteCropImage, setDeleteCropImage] = useState("");
    const [base64ToImageFile, setBase64ToImageFile] = useState([]);
    const [defaultCroped,setDefaultCroped] = useState(false);
    const [particulerEditData, setParticulerEditData] = useState("");
    const [imageMmSize,setImageMmSize] = useState(130);
    const [testingImageUpload,setTestingImageUpload] = useState("");

    // g_kbs
    const [checkOutDrawer, setCheckOutDrawer] = useState(false);
    const [aplyLoaderAndCheckOut, setAplyLoaderAndCheckOut] = useState(false);
    const [shopifyURL, setShopifyURL] = useState("");
    const [aplyLoader, setAplyLoader] = useState(false);
    
    // upload and create
    const [uploadResponse, setUploadResponse] = useState([]);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [uploadResDltIndex, setUploadResultDltIndex]= useState();
    const [uploadCropIndex, setUploadCropIndex] = useState();
    const [uploadAllRes, setUploadAllRes] = useState("");

    const [fileArray, setFileArray] = useState([]);
    const [allUploadRes, setAllUploadRes] = useState([]);
    const [uploadApiHandling, setUploadApiHandling] = useState(false);


    return (
        <AppContext.Provider value={{croppedData, setCroppedData,cropModal, setCropModal, partiCulerData, setPartiCulerData, deleteCropImage, setDeleteCropImage, getButtonName, setGetButton,base64ToImageFile, setBase64ToImageFile,defaultCroped,setDefaultCroped,particulerEditData, setParticulerEditData,imageMmSize,setImageMmSize,testingImageUpload,setTestingImageUpload, checkOutDrawer, setCheckOutDrawer, aplyLoaderAndCheckOut, setAplyLoaderAndCheckOut, shopifyURL, setShopifyURL, aplyLoader, setAplyLoader, uploadResponse, setUploadResponse, uploadLoader, setUploadLoader, uploadResDltIndex, setUploadResultDltIndex, uploadCropIndex, setUploadCropIndex, uploadAllRes, setUploadAllRes,fileArray, setFileArray,allUploadRes, setAllUploadRes,uploadApiHandling, setUploadApiHandling
        }}>
            {children}
        </AppContext.Provider>
    )
}
export function UsePics() {
    return useContext(AppContext)
}