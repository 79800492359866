export function GenerateImgInformation(file, id) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const imageInfo = {
            id: id,
            width: img.width,
            height: img.height,
            main: event.target.result,
          };
          resolve(imageInfo);
        };
      };

      reader.readAsDataURL(file);
    } catch (error) {
      reject("Failed" + error);
    }
  });
}
