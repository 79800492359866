import { Fragment, useEffect } from "react";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { UsePics } from "../State/AppContext";

const Footer = ({ label }) => {
  const {
    croppedData,
    getButtonName,
    setBase64ToImageFile,
    setTestingImageUpload,
    setCheckOutDrawer,
    fileArray,
    setFileArray,
    setUploadLoader,
    imageMmSize,
    allUploadRes, setAllUploadRes,
    uploadApiHandling, setUploadApiHandling
  } = UsePics();

  const uniId = uuidv4();

  const fetchAndConvertToBlob = async (url) => {
    const response = await fetch(url);
    const blobData = await response.blob();
    return blobData;
  };

  const handleImageBase64ToFile = async () => {
    const storeFileImage = [];
    await Promise.all(
      croppedData.map(async (imageData, index) => {
        if (!(imageData.dataUrl instanceof Blob)) {
          const blobData = await fetchAndConvertToBlob(imageData.dataUrl);
          const { size, type } = blobData;
          const file = new File(
            [blobData],
            `${getButtonName}_JMD_1;100;0;${Date.now()}${index};Sticpics:customer_name.${imageData?.name
              ?.split(".")
              ?.at(-1)}`,
            {
              lastModified: Date.now(),
              type,
              size,
            }
          );
          storeFileImage?.push(file);
        } else {
          const [, mime, data] = imageData?.dataUrl?.match(
            /^data:(.*?);(.*?),(.*)$/
          );
          const dataArr = new Uint8Array(
            atob(data)
              ?.split("")
              ?.map((c) => c?.charCodeAt(0))
          );
          const fileName = imageData?.name?.replace(/ /g, "_");
          const file = new File([dataArr], fileName, { type: mime });
          const compressedFile = file;
          storeFileImage.push(compressedFile);
        }
      })
    );
    setBase64ToImageFile(storeFileImage);
    setTestingImageUpload(storeFileImage);
  };

  useEffect(() => {
    croppedData.length > 0 && handleImageBase64ToFile();
  }, [croppedData]);

  const newData = croppedData.map((item) => {
    // Convert base64 data to a Blob
    const byteCharacters = atob(item.dataUrl.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
  
    // Create a File object from the Blob
    const file = new File([blob], 'image.png', { type: 'image/png' });
  
    return {
      uniId: uniId,
      dataUrl: file,
      frame: `Sticpics-${getButtonName}-Framed-Print`,
      imgSize: imageMmSize,
    };
  });
  
  const handleFooterUpload = async () => {
      // Send custom event to Google Analytics
      window.gtag('event', 'FirstCheckoutButtonClick', {
        'event_category': 'First Checkout',
      });
    try {
      setUploadLoader(true);
      const apiUrl = `${process.env.REACT_APP_BASE_URL}uploadImage`;
      const promises = newData.map(async (data) => {
        try {
          const formData = new FormData();
          formData.append('image', data.dataUrl); // Append the File object to FormData
          formData.append('uniId', data.uniId);
          formData.append('frame', data.frame);
          formData.append('imgSize', data.imgSize);
  
          const response = await axios.post(apiUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log("Uploaded image:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error uploading image:", error);
          throw error;
        }
      });
  
      const allResults = await Promise.all(promises);
      setUploadLoader(false);
      console.log("All images uploaded successfully:", allResults);
      setAllUploadRes(allResults);
      setUploadApiHandling(true);
    } catch (error) {
      toast.error("An error occurred while processing your request.");
      setUploadLoader(false);
    }
  };

  useEffect(() => {
    // Track pageview for the checkout page
    window.gtag('config', 'G-KL3EGEBFXF', {
      'page_path': '/SelectPhotos',
    });
  }, []);
  
  return (
    <Fragment>
      <div className="footerHolder">
        {croppedData?.length > 0 ? (
          <div className="footer">
            <Button
              id="checkout-btn"
              className="footerButton"
              onClick={() => {
                setCheckOutDrawer(true);
                !uploadApiHandling && handleFooterUpload();
              }}
            >
              {label}
            </Button>
          </div>
        ) : (
          <div className="footer">
            <Button
              className="footerButton"
              onClick={() =>
                toast.error(
                  "Please add more photos as its a minimum order of 3 sticpics"
                )
              }
            >
              {label}
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Footer;
