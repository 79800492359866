import { Provider } from './State/AppContext';
import { Slide, ToastContainer } from 'react-toastify';

import Main from './Pages/Main';

import './App.scss';
import 'cropperjs/dist/cropper.css';
import 'react-contexify/ReactContexify.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import "react-circular-progressbar/dist/styles.css";

function App() {
  return (
    <>
      <ToastContainer autoClose={3000} position='top-center' theme='colored' transition={Slide} />
        <Provider>
          <Main />
        </Provider>
    </>
  );
}

export default App;
