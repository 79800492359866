import { Fragment, useState, useEffect } from "react";

import { Row } from "react-bootstrap";
import axios from "axios";

import { UsePics } from "../State/AppContext";
import { FaCircleNotch, FaPlus, FaMinus } from "react-icons/fa";
import { toast } from "react-toastify";

const OrderInformation = ({ activeStyle }) => {
  const {
    croppedData,
    getButtonName,
    aplyLoaderAndCheckOut,
    setAplyLoaderAndCheckOut,
    setShopifyURL,
    setAplyLoader,
    imageMmSize,
    testingImageUpload,
    uploadLoader,
    uploadResponse,
    allUploadRes,
  } = UsePics();
  const [commonState, setCommonState] = useState({
    discountCode: "",
    applyLoader: false,
  });
  const [discountIcon, setDisCountIcon] = useState(false);
  const [messageCode, setMessageCode] = useState(false);
  const [couponApplyAmmount, setCouponApplyAmmount] = useState("");

  const [discountData, setDiscountData] = useState(false);
  function calculatePrice(arr) {
    if (arr.length <= 2) {
        return arr.length === 1 ? 15.99 : 30;
    } else {
        return (39.99 + 10 * (arr.length - 3)).toFixed(2);
    }
}
  //   g_kbs
  const handleDrawerCheckOut = async () => {
     // Send custom event to Google Analytics
     window.gtag('event', 'ApplyButtonClick', {
      'event_category': 'Apply Button',
    });
    setCommonState({ ...commonState, applyLoader: true });
    setAplyLoader(true);
    // const formData = new FormData();
    // const totalPrice = (
    //   croppedData?.length < 3 ? 0.0 : 39.99 + (croppedData?.length - 3) * 10
    // ).toFixed(2);
    // formData.append("Totalprice", totalPrice);
    // formData.append("title", `Sticpics ${getButtonName} Framed Print`);
    // formData.append("discountCode", commonState?.discountCode);
    // formData.append("imageSize", imageMmSize);
    // testingImageUpload &&
    //   testingImageUpload?.forEach((image) => {
    //     formData.append("image", image);
    //   });

    // const apiUrl = `${process.env.REACT_APP_BASE_URL}uploadImage`;
    // try {
    //   const res = await axios.post(apiUrl, formData);
    //   if (res?.status >= 200 && res?.status < 400) {
    //     const data = res?.data?.data;
    //     setCouponApplyAmmount(data);
    //     setCommonState({ ...commonState, applyLoader: false });
    //     setAplyLoader(false);
    //     setAplyLoaderAndCheckOut(true);
    //     setShopifyURL(data?.url);
    //     setDiscountData(true);
    //   } else {
    //     const error = res?.data;
    //     toast.error(error?.message);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   setCommonState({ ...commonState, applyLoader: false });
    //   setAplyLoader(false);
    //   toast.error("An error occurred while processing your request.");
    // }
    const totalPrice = calculatePrice(croppedData);
    // formData.append("Totalprice", totalPrice);
    // formData.append("title", `Sticpics-${getButtonName}-Framed-Print`);
    // formData.append("imageSize", imageMmSize);
    // testingImageUpload &&
    //   testingImageUpload?.forEach((image) => {
    //     formData.append("image", image);
    //   });

    let data = {
      Totalprice: totalPrice,
      allUrl: allUploadRes.map(({ ImageUrl }) => {
        return ImageUrl;
      }),
      discountCode: commonState?.discountCode,
      title: `Sticpics-${getButtonName}-Framed-Print`,
    };

    const apiUrl = `${process.env.REACT_APP_BASE_URL}createProducts`;
    try {
      const res = await axios.post(apiUrl, data);
      if (res?.status >= 200 && res?.status < 400) {
        const data = res?.data?.data;
        setCouponApplyAmmount(data);
        setCommonState({ ...commonState, applyLoader: false });
        setAplyLoader(false);
        setAplyLoaderAndCheckOut(true);
        setShopifyURL(data?.url);
        setDiscountData(true);
      } else {
        const error = res?.data;
        toast.error(error?.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while processing your request.");
      setCommonState({ ...commonState, applyLoader: false });
      setAplyLoader(false);
    }
  };

  // Apply coupon code validation
  const handleApplyCouponCodeApi = (e) => {
    e.preventDefault();
    if (!commonState.discountCode) {
      setMessageCode(true);
    } else if (
      // commonState.discountCode.length > 1
      // ||
      commonState.discountCode.length < 1
    ) {
      toast.error("Invalid coupon code!");
    } else {
      handleDrawerCheckOut();
    }
  };

  useEffect(() => {
    // Track pageview for the checkout page
    window.gtag("config", "G-KL3EGEBFXF", {
      page_path: "/SelectPhotos",
    });
  }, []);

  return (
    <div>
      <div>
        <Row>
          <h5> Summary </h5>
        </Row>
        <Row>
          <div className="infoRow">
            <div className="left">
              <h6>Photos Added:</h6>
            </div>
            <div className="right">{croppedData?.length}</div>
          </div>
        </Row>
        <Row>
          <div className="infoRow">
            <div className="left">
              <h6>Style Selected:</h6>
            </div>
            <div className="right">{activeStyle?.name}</div>
          </div>
        </Row>
        <Row>
          <div className="infoRow">
            <div className="left">
              <h6>Delivery:</h6>
            </div>
            <div className="right">Free</div>
          </div>
        </Row>
        <Row>
          <div className="infoRow">
            <div className="left">
              <h6>
                {`${
                  couponApplyAmmount.discountAmount && discountData
                    ? `Sub Total:`
                    : `Total:`
                }`}
              </h6>
            </div>
            {couponApplyAmmount.subtotal && discountData ? (
              <div
                className={`right ${
                  couponApplyAmmount.discountAmount && discountData
                    ? `cut-ammount`
                    : ``
                }`}
              >
                €{couponApplyAmmount.subtotal}
              </div>
            ) : (
              <div
                className={`right ${
                  couponApplyAmmount.discountAmount && discountData
                    ? `cut-ammount`
                    : ``
                }`}
              >
                €
                {calculatePrice(croppedData)}
              </div>
            )}
          </div>
        </Row>

        {couponApplyAmmount.discountAmount && discountData && (
          <Fragment>
            <Row>
              <div className="infoRow">
                <div className="left">
                  <h6>Discount:</h6>
                </div>
                <div className="right">
                  €{couponApplyAmmount.discountAmount}
                </div>
              </div>
            </Row>
            <Row>
              <div className="infoRow">
                <div className="left">
                  <h6>Total:</h6>
                </div>
                <div className="right">
                  €{couponApplyAmmount.afterDiscountAmount}
                </div>
              </div>
            </Row>
          </Fragment>
        )}

        <Row>
          <b
            onClick={() => setDisCountIcon(!discountIcon)}
            className="discount-icon"
          >
            Discount Code <span>{discountIcon ? <FaMinus /> : <FaPlus />}</span>
          </b>
          {discountIcon && (
            <div className="infoRow">
              <div className="left">
                <input
                  type="text"
                  className="discount-field"
                  placeholder="Enter discount code"
                  value={commonState.discountCode}
                  onChange={(e) =>
                    setCommonState({
                      ...commonState,
                      discountCode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="right">
                {!aplyLoaderAndCheckOut ? (
                  <button
                    id="apply-btn"
                    className="discount-button"
                    onClick={(e) => handleApplyCouponCodeApi(e)}
                    type="button"
                  >
                    Apply
                    <span className="btn-loader discount-loader">
                      {commonState.applyLoader && <FaCircleNotch />}
                    </span>
                  </button>
                ) : (
                  <button
                    className="discount-button"
                    onClick={() => {
                      setShopifyURL("");
                      setAplyLoaderAndCheckOut(false);
                      setDiscountData(false);
                      setCommonState({ ...commonState, discountCode: "" });
                    }}
                    type="button"
                  >
                    Remove
                  </button>
                )}
              </div>
              {!commonState.discountCode && messageCode && (
                <span className="coupon-code-error">
                  Please enter coupon code
                </span>
              )}
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default OrderInformation;
