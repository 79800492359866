export const ApiKey = "AwDUla4uRT3GfDinUA6t9z";

export const PickerOptions = {
  accept: 'image/*',
  maxFiles: 10,
  transformations: {
    crop: {
      aspectRatio: 1 / 1
    }
  },
  fromSources: ['instagram', 'facebook', 'googledrive']
};

export const AvailableOptions = [
  {
    name: 'White-Mount',
    img: "./assets/Photos/mounted.png",
    mm: 130
  },
  {
    name: 'Black-Mount',
    img: "./assets/Photos/Mountedd.png",
    mm: 130
  },
  {
    name: 'White',
    img: "./assets/Photos/Mount.jpg",
    mm: 180
  },
  {
    name: 'Black',
    img: "./assets/Photos/Mount-PNG.png",
    mm: 180
  }
];
