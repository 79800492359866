import { Fragment } from "react";

const CanvasBorderLess3d = ({ data }) => {
  return (
    <Fragment>
      <span className="canvas-edge"></span>
      <div className="reflection-head ref-head-mob-vert">
        <div id="mob-vert-top-head">
          <img src={data} alt="" />
        </div>
        <div id="mob-vert-right-head">
          <img src={data} alt="" />
        </div>
      </div>

      <div className="reflection-head ref-head-mob-hor">
        <div id="mob-hor-top-head">
          <img src={data} alt="" />
        </div>
        <div id="mob-hor-right-head">
          <img src={data} alt="" />
        </div>
      </div>

      <div className="reflection-head ref-head-desktop">
        <div id="desktop-top-head">
          <img src={data} alt="" />
        </div>
        <div id="desktop-right-head">
          <img src={data} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default CanvasBorderLess3d;
