import { Modal, Button } from "react-bootstrap";

const ModalProvider = ({ showModal, modalToShow, closeModal }) => {
  const FaqContent = () => {
    return (
      <div className="faq-cont-main">
        <div className="faq-cont-head mb-1">What is Sticpics?</div>
        <div className="faq-para mb-4">
          Introducing Sticpics—the effortless newway to turn your digital photos
          into wall-ready solid wood framed photo tiles. Choose from a white or black frame as well as mounted or borderless which ensures there is a suitable style for everyone.
          We include a repositionable tape with each frame or photo tile which means you can place on your wall without damaging it. Feel like positioning your framed print or photo tile permanently? No problem, we also include permanent hanging systems!
        </div>

        <div className="faq-cont-head mb-1">
          How do I place an order?
        </div>
        <div className="faq-para mb-4">
          Your now on our website - click lets go below and you can upload photos from your computer or smartphone. Select your style and sumbit your order and we will sort out the rest. 
    
        </div>

        <div className="faq-cont-head mb-1">
          Tell me all about your sticpics!
        </div>

        <div className="faq-para mb-4">
          We have one size to start out. Its a 20 x 20cm Framed photo print. We combine a solid wood photo frame, a fine art photo print, self adhesive backing 
          and some tape for hanging to create an amazing product designed to showcase your cherished moments.
      
        </div>

        <div className="faq-cont-head mb-1">How do your promo codes work?</div>

        <div className="faq-para mb-4">Our promo codes must be input before you proceed to the the checkout - once you click checkout a slide out cart will appear and
        there is a section where you can expand the discount code section. Expand and then copy and paste your discounts code.
    
        </div>

        <div className="faq-cont-head mb-1">
          Uh oh! I placed my order and need to change something.
        </div>

        <div className="faq-para mb-4">
Unfortunately we cant allow changes to any orders. Orders get printed as soon as your order as submitted so if anything is incorrect with your order please email us on info@sticpics.ie 
mentioning your order number and one of our team should be able to sort the issue out for you.
        </div>

        <div className="faq-cont-head mb-1">I got my Sticpics, now what?</div>

        <div className="faq-para mb-4">
         First, grab a dry cloth and give the
          wall a quick wipe. Once you're ready, peel off that protective sheet
          from the sticky tape and apply pressure evenly around the frame.
          For using the permanent hanging systems will need a hook or nail extending from the wall. For our framed photo tiles just hang on the slip on hanger which is located at the back of your frame 

          <p>Please note that the sticky tape provided can only be moved 5 times and then the tape begins to weaken. We would recommend using the permanent method for fixing once you are happy with the placement of your Sticpics.
          </p>
        </div>

        <div className="faq-cont-head mb-1">
          What if I am not happy with my sticpics?
        </div>

        <div className="faq-para mb-4">
          We stand behind our product. If for any
          reason you're not thrilled with your purchase, just reach out to us on info@sticpics.ie
          We take your happiness seriously and we'll do our very best to achieve
          it!
        </div>

        <div className="faq-cont-head mb-1">Tell me about your shipping.</div>

        <div className="faq-para mb-4">
         We offer free shipping in Ireland so no need to worry!
        </div>

        <div className="faq-cont-head mb-1">
          How will I know the status of my order?
        </div>

        <div className="faq-para mb-4">
         You will receive a shipment email once your Sticpics leave the production facility and packages are usually next day delivery in Ireland.
        </div>

        <div className="faq-cont-head mb-1">Can I give Sticpics as a gift?</div>

        <div className="faq-para mb-4">
       We are working on creating a gift card product that is available on our homepage - in the meantime please email us on info@sticpics.ie and mention your required gift card amount and we can arrange that for you.
        </div>
      </div>
    );
  };

  const ContactUsContent = () => {
    return (
      <div>
        <div className="faq-cont-head">Email Address: </div>
        <div className="faq-para mb-4">info@sticpics.ie</div>
        <p>Will will endeaver to get back to you as quick as possible</p>
      
      </div>
    );
  };

  // const GeneralContent = () => {
  //   return (
  //     <div style={{ width: '100%' }}>
  //       General
  //     </div>
  //   )
  // }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      id={modalToShow}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalToShow}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalToShow === "FAQ"
            ? FaqContent()
            : modalToShow === "ContactUs"
            ? ContactUsContent()
            : ""
          // GeneralContent()
        }
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ModalProvider;
