import { Fragment, useState, useEffect } from "react";

import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import LogoGif from "../assests/images/logo.gif";
import Hero1Img from "../assests/images/hero-img1-new.webp";
import Hero2Img from "../assests/images/hero-img2-new.webp";
import Hero3Img from "../assests/images/hero-img3-new.jpg";
import HeroImg from "../assests/images/Sticpics-Black-Frame-Image--square-crop.jpg"
import paymentLogo from "../assests/images/Secure-checkout.jpg";
import SapatronVidMp4 from "../assests/images/Sticpics - Short Reel-2.mp4";
import ModalProvider from "../Components/ModalProvider";

const HomePage2 = () => {
  const navigate = useNavigate();
  const [videoModal, setVideoModal] = useState(false);
  const [headData, setheadData] = useState({
    showModal: false,
    modalToShow: null,
  });

  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    mobileFirst: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settings2 = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    mobileFirst: true,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const processDropDownClick = (option) => {
    setheadData({
      showModal: true,
      modalToShow: option,
    });
  };

  const handleUploadBtn = () => {
    // Track the pageview event when the button is clicked
    window.gtag("event", "uploadButtonClicked", {
      event_category: "button_click", // You can customize the event category if needed
      event_label: "Upload Button", // Optional label to provide more context
    });

    // Navigate to the upload image page
    navigate("/SelectPhotos");
  };

  useEffect(() => {
    // Track the pageview event when the home page component mounts
    window.gtag('event', 'HomePageLand', {
      'event_category': 'engagement', // You can customize the event category if needed
      'event_label': 'Home Page', // Optional label to provide more context
    });
  }, []);

//add this for optimization
const LazyImage = ({ src, alt }) => {
  return <img src={src} alt={alt} loading="lazy" />;
};


  return (
    <Fragment>
      <header>
        <div className="logo">
          <img src={LogoGif} alt=""  width="200" height="100"/>
        </div>
      </header>

      <div className="homepage-con">
        <section className="hero-sec">
          <div className="hero-sec-inner container">
            <div className="left-sec">


              <Slider {...settings}>
              <div className="tri-hero-img">
                  <div className="thi-left-head">
                    <div className="thi-left">
                      <LazyImage src={HeroImg} alt="Hero" />
                    </div>
                  </div>
                </div>
                <div className="tri-hero-img">
                  <div className="thi-left-head ">
                    <div className="thi-left">
                      {/* <img src={Hero1Img} alt="" /> */}

                      <LazyImage src={Hero1Img} alt="Hero 1" />
                    </div>
                  </div>
                </div>
                <div className="tri-hero-img">
                  <div className="thi-left-head">
                    <div className="thi-left">
                      {/* <img src={Hero2Img} alt="" /> */}
                      <LazyImage src={Hero2Img} alt="Hero 2" />
                    </div>
                  </div>
                </div>
                <div className="tri-hero-img">
                  <div className="thi-left-head">
                    <div className="thi-left">
                      {/* <img src={Hero3Img} alt="" /> */}
                      <LazyImage src={Hero3Img} alt="Hero 3" />
                    </div>
                  </div>
                </div>
              </Slider>


            </div>
            <div className="right-sec">
              <div className="heading-70">
                Turn your photos into stunning wall art with solid wood frames
              </div>
              <div className="text-para">
                Design beautiful walls filled with memories using your favorite
                photos.
              </div>
              <div className="sap-btn-dark">
                <button onClick={() => handleUploadBtn()}>Upload Images</button>
              </div>
              <div className="shipping-subtitle">
                <i className="fa fa-plane" aria-hidden="true"></i> Free shipping
                in Ireland!
              </div>
              <div className="payment-logo">
              <LazyImage src={paymentLogo} alt="payment-logo"/>
            </div>
            </div>
            
          </div>
        </section>

        <section className="tri-icon-sec">
          <div className="tri-icon-sec-inner container">
            <ul className="tri-icon-list desktop-only">
              <li>
                <div className="til-ico">
                  <img
                    src="./assets/Photos/homePageIcon/til-icon-1.png"
                    alt="til-icon"
                  />
                </div>
                <div className="til-title">Solid wood frames</div>
                <div className="til-subtitle">Sustainable framing</div>
              </li>
              <li>
                <div className="til-ico">
                  <img
                    src="./assets/Photos/homePageIcon/til-icon-2.png"
                    alt="til-icon"
                  />
                </div>
                <div className="til-title">Archival photo prints</div>
                <div className="til-subtitle">
                  Prints that will last up to 75 years
                </div>
              </li>
              <li>
                <div className="til-ico">
                  <img
                    src="./assets/Photos/homePageIcon/til-icon-3.png"
                    alt="til-icon"
                  />
                </div>
                <div className="til-title">No nails needed</div>
                <div className="til-subtitle">Tape that sticks to walls</div>
              </li>
              <li>
                <div className="til-ico">
                  <img
                    src="./assets/Photos/homePageIcon/til-icon-4.png"
                    alt="til-icon"
                  />
                </div>
                <div className="til-title">Glazing for protection</div>
                <div className="til-subtitle">
                  Protect your special memories
                </div>
              </li>
              <li>
                <div className="til-ico">
                  <img
                    src="./assets/Photos/homePageIcon/til-icon-5.png"
                    alt="til-icon"
                  />
                </div>
                <div className="til-title">Free Shipping</div>
                <div className="til-subtitle">We ship within a few days!</div>
              </li>
            </ul>
            <div className="tri-icon-list-slider mobile-only">
              <Slider {...settings2}>
                <div className="til-head-sap">
                  <div className="til-ico">
                    <img
                      src="./assets/Photos/homePageIcon/til-icon-1.png"
                      alt="til-icon"
                    />
                  </div>
                  <div className="til-title">Solid wood frames</div>
                  <div className="til-subtitle">Sustainable framing</div>
                </div>
                <div className="til-head-sap">
                  <div className="til-ico">
                    <img
                      src="./assets/Photos/homePageIcon/til-icon-2.png"
                      alt="til-icon"
                    />
                  </div>
                  <div className="til-title">Archival photo prints</div>
                  <div className="til-subtitle">
                    Prints that will last up to 75 years
                  </div>
                </div>
                <div className="til-head-sap">
                  <div className="til-ico">
                    <img
                      src="./assets/Photos/homePageIcon/til-icon-3.png"
                      alt="til-icon"
                    />
                  </div>
                  <div className="til-title">No nails needed</div>
                  <div className="til-subtitle">Tape that sticks to walls</div>
                </div>
                <div className="til-head-sap">
                  <div className="til-ico">
                    <img
                      src="./assets/Photos/homePageIcon/til-icon-4.png"
                      alt="til-icon"
                    />
                  </div>
                  <div className="til-title">Glazing for protection</div>
                  <div className="til-subtitle">
                    Protect your special memories
                  </div>
                </div>
                <div className="til-head-sap">
                  <div className="til-ico">
                    <img
                      src="./assets/Photos/homePageIcon/til-icon-5.png"
                      alt="til-icon"
                    />
                  </div>
                  <div className="til-title">Free Shipping</div>
                  <div className="til-subtitle">We ship within a few days!</div>
                </div>
              </Slider>
            </div>
          </div>
        </section>

        <section className="sapatron-sec">
          <div className="sapatron-sec-inner container grey-back">
            <div className="left-sec">
              <div className="heading-44">
                Beautiful solid wood frames that stick directly to your wall
              </div>
              <div className="text-para">
                Get your photos in stylish frames that stick directly to your
                wall and leave no damage behind! We also include a permanent
                hanging fixture once you have found the perfect location for
                your framed print
              </div>
            </div>
            <div className="right-sec">
              <div className="tron-img">
                <video autoPlay preload="auto" loop muted playsInline={true}>
                  <source src={SapatronVidMp4} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>

        <section className="sapatron-sec home-tri-sec">
          <div className="sapatron-sec-inner container grey-back">
            <div className="stipics-list1">
              {/* <img src={Hero2Img} alt="" /> */}
              <LazyImage src={Hero2Img} alt="Hero 2" />
              <h3> Photo prints in solid wood frames</h3>
              <div>
                Sticpics offers an online framed photo printing service for
                digital photo prints in a range of solid wood framing options.
                All our products are printed with archival inks and framed in
                Ireland.{" "}
              </div>
            </div>
            <div className="stipics-list1">

            <LazyImage src={Hero1Img}  alt="Sticpics Photos Framed | Photo tiles framed" />
              {/* <img
                src={Hero1Img}
                alt="Sticpics Photos Framed | Photo tiles framed"
              /> */}
              <h3> Photo tiles that stick to walls </h3>
              <div>
                Sticpics can be hung on walls using a double sided tape which
                does not damage walls. The frames can be moved up to 5 times- we
                would recommend finding the right place for your cherished
                memeory and use the permanet fixting method
              </div>
            </div>
            <div className="stipics-list1">

            <LazyImage src={Hero3Img}  alt="Sticpics Photos Framed | Photo tiles framed" />

              {/* <img
                src={Hero3Img}
                alt="Sticpics Photos Framed | Photo tiles framed"
              /> */}
              <h3> Framed photo prints</h3>
              <div>
                Sticpics offers one simple frame size measuring 20 x 20cm
                externally. We use a solid wood frame profile that is 15mm wide
                & 15mm deep available in white or black & with a border or
                without. Sticpics are manufactured in Ireland.
              </div>
            </div>
          </div>

          <div className="center-button mt-4">
            <div className="sap-btn-dark">
              <button onClick={() => handleUploadBtn()}>
                Upload Images
              </button>
            </div>
          </div>
        </section>

        {/* <section className="sp-vid-sec">
          <div className="sp-vid-sec-inner container">
            <div className="sp-vid-head">
              <div className="sp-vid-con">
                <div className="heading-34">
                  Choose Sticpics - beautiful solid wood photo products
                </div>
                <div className="sap-btn-light btn-sm"></div>
              </div>
              <div className="sp-vid-thumb">
                <img src={VidThumbWebp} alt="" />
              </div>
            </div>
          </div>
        </section> */}
      </div>

      <footer>
        <div className="footer-inner container">
          <div className="foot-con-heading">
            <div className="foot-con" id="foot-con1">
              <div className="foot-heading">About</div>
              <ul>
                <li>
                  {/* <a onClick={() => processDropDownClick("FAQ")}>FAQs</a> */}
                  <Link onClick={() => processDropDownClick("FAQ")}>FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="foot-copy">
            <div className="copyright-text">
              © Copyright 2023-2024 SticPics, LTD
            </div>
          </div>
        </div>
      </footer>
      {/* faq modal */}
      <ModalProvider
        modalToShow={headData.modalToShow}
        showModal={headData.showModal}
        closeModal={() => setheadData({ showModal: false })}
      />
      <Modal
        show={videoModal}
        onHide={() => setVideoModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="about_us_video"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            src="./assets/video/frame2.mp4"
            title="iframe"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default HomePage2;
