import { useState, useRef, memo, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { Cropper } from "react-cropper";
import { Button, Modal } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";

import { UsePics } from "../State/AppContext";
import { GenerateImgInformation } from "./../CropUtility";
import { toast } from "react-toastify";

const UploadButton = ({
  cropModal,
  setCropmodal,
  framAndBorder,
  particulerEditData,
}) => {
  let fileUploader = useRef();
  const cropperRef = useRef(null);
  const [zoomValue, setZoomValue] = useState(0.1);
  const {
    croppedData,
    setCroppedData,
    defaultCroped,
    setParticulerEditData,
    getButtonName,
    imageMmSize,
    setUploadResponse,
    uploadResponse,
    setUploadLoader,
    uploadCropIndex, setUploadCropIndex,
    uploadAllRes, setUploadAllRes,
    fileArray, setFileArray,
    setUploadApiHandling,
  } = UsePics();
  


  async function uploadFromInput(event) {
    setUploadApiHandling(false);
    const files = event.target.files;
    const images = Array.from(files);
    setFileArray(images);

    if (files[0]) {
      const uploadedData = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uuid = uuidv4();
        const res = await GenerateImgInformation(file, uuid);
        if (res.width < 1279 || res.height < 719) {
          toast.error(`${file.name} not 1280x720 pixels image`);
          continue;
        }

        uploadedData.push({
          name: file.name,
          dataUrl: res.main,
          unCropped: res.main,
          imageType: file.type,
          isStandardCrop: true,
          imgid: uuid,
          hasRecievedFileStackUrl: false,
          id: croppedData?.length + i,
        });
      }
      setCroppedData([...croppedData, ...uploadedData]);

      // const images = Array.from(files);
      // const formData = new FormData();
      // formData.append("title", `Sticpics-${getButtonName}-Framed-Print`);
      // formData.append("imageSize", imageMmSize);
      // formData.append(
      //   "imageId",
      //   uploadResponse?.length > 0 ? uploadAllRes?.data?.imageId : ""
      // );
      // images &&
      //   images?.forEach((image, index) => {
      //     formData.append("image", image);
      //     // formData.append("ind", index + uploadResponse?.length);
      //   });
      // const apiUrl = `${process.env.REACT_APP_BASE_URL}uploadImage`;
      // try {
      //   setUploadLoader(true);
      //   const res = await axios.post(apiUrl, formData);
      //   if (res?.status >= 200 && res?.status < 400) {
      //     setUploadLoader(false);
      //     setUploadResponse([...uploadResponse, ...res?.data?.data]);
      //     setUploadAllRes(res);
      //   } else {
      //     const error = res?.data;
      //     toast.error(error?.message);
      //     setUploadLoader(false);
      //   }
      // } catch (error) {
      //   toast.error("An error occurred while processing your request.");
      //   setUploadLoader(false);
      // }
    }
  }

  const Cropped = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const img = cropper?.getCroppedCanvas()?.toDataURL();
    setZoomValue(0.1);
    const updateCropData =
      croppedData?.length > 0 &&
      croppedData?.map((ele) => {
        if (ele?.id === particulerEditData?.id) {
          ele.isCropped = true;
          return { ...ele, dataUrl: img };
        }
        return ele;
      });
    setCroppedData(updateCropData);

    //   let updateUploadResponse = []
    //   uploadResponse && uploadResponse?.filter((ele, index) => {if(index === uploadCropIndex){
    //     return updateUploadResponse.push({...ele, dataUrl: img})
    //   }});


    // let data = {
    //   imageBase64 : updateUploadResponse[0]?.dataUrl,
    //   title :  updateUploadResponse[0]?.title,
    //   imageSize: imageMmSize,
    //   id :  updateUploadResponse[0]?.id,
    // };

    // const apiUrl = `${process.env.REACT_APP_BASE_URL}updateImage`;
    // try {
    //   const res = await axios.post(apiUrl, data);
    //   if (res?.status >= 200 && res?.status < 400) {
      
    //   } else {
       
    //   }
    // } catch (error) {
    //   toast.error("An error occurred while processing your request.");
    // }
  };

  const handleZoomChange = (e) => {
    const newZoomValue = parseFloat(e.target.value);
    setZoomValue(newZoomValue);
    const cropper = cropperRef.current.cropper;
    if (cropper) {
      cropper.zoomTo(newZoomValue);
    }
  };

  const handleZoomIncrement = () => {
    const newZoomValue = Math.min(zoomValue + 0.1, 1);
    setZoomValue(newZoomValue);
    const cropper = cropperRef.current.cropper;
    if (cropper) {
      cropper.zoomTo(newZoomValue);
    }
  };

  const handleZoomDecrement = () => {
    const newZoomValue = Math.max(zoomValue - 0.1, 0.1);
    setZoomValue(newZoomValue);
    const cropper = cropperRef.current.cropper;
    if (cropper) {
      cropper.zoomTo(newZoomValue);
    }
  };

  const handleModalDoneButton = () => {
    setCropmodal(false);
    Cropped();
  };

  useEffect(() => {
    defaultCroped && handleModalDoneButton();
  }, [defaultCroped]);

  return (
    <div>
      <div
        className={`uploadButtonMouseOver ${
          croppedData?.length > 0 ? `position-fix` : ``
        }`}
      >
        <div className="outer">
          <div
            htmlFor="fileUpload"
            onClick={() => fileUploader.current.click()}
            className="file_upload_top"
          >
            <span className="uploadIcon">
              <img src="./assets/Photos/upload-icon.svg" alt="" />
            </span>
            <h4 className="uploadFromPcText">Upload Photos</h4>
            <input
              type="file"
              accept=".jpg,.jpeg,.heic,.heif,.png"
              multiple
              autoComplete="off"
              onClick={(e) => {
                e.target.value = null;
                fileUploader.current.click();
              }}
              id="fileUpload"
              ref={fileUploader}
              onChange={(e) => {
                uploadFromInput(e);
              }}
            />
          </div>
        </div>
        <div
          className="hover_plus_icon"
          onClick={() => fileUploader.current.click()}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="0.625em"
              viewBox="0 0 448 512"
            >
              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
            </svg>
          </span>
        </div>
      </div>

      <Modal
        show={cropModal}
        onHide={() => {
          setCropmodal(false);
          setParticulerEditData("");
        }}
        backdrop="static"
        id="crop-modal"
        className={`${
          framAndBorder.selectPhoto === `White` ? `white-modal` : ``
        }`}
      >
        <Modal.Body>
          <Button
            variant="light"
            onClick={() => {
              setCropmodal(false);
              setZoomValue(0.1);
            }}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </Button>
          <b className="adjust">Adjust Crop</b>
          <p className="drag">Scroll to zoom, drag to move</p>
          <div className={`crop-pop-up`}>
            <div className={`${framAndBorder.selectPhoto}`}>
              <Cropper
                src={particulerEditData.unCropped}
                aspectRatio={1}
                className="crop-pop-img-head"
                guides={false}
                style={{ height: 240, width: "100%" }}
                ref={cropperRef}
                zoomable={true}
                background={false}
                dragMode="move"
                viewMode={1}
              />
            </div>
            <div className="input-range">
              <button
                className="rounded-circle border-0 decrement fw-bold btn btn-lg"
                type="button"
                onClick={handleZoomDecrement}
              >
                <FaMinus />
              </button>
              <input
                type="range"
                min="0.1"
                max={1}
                step="0.01"
                value={zoomValue}
                onChange={(e) => handleZoomChange(e)}
                className="crop-range"
              />
              <button
                className="rounded-circle border-0 increment fw-bold btn btn-lg"
                type="button"
                onClick={handleZoomIncrement}
              >
                <FaPlus />
              </button>
            </div>

            <button
              type="button"
              className="custom-button"
              onClick={() => {
                handleModalDoneButton();
              }}
            >
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(UploadButton);
