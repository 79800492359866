import { Menu, Item } from 'react-contexify';

const ContextMenu = ({ id, removePhoto, cropAction }) => {
  return (
    <Menu id={id}>
      <Item onClick={removePhoto}>
        <i className="fa fa-trash red" />Delete
      </Item>
      <Item onClick={cropAction}>
        <i className="fa fa-crop blue" />Crop
      </Item>
      <Item>
        <i className="fa fa-times gray" aria-hidden="true"></i>Dismiss
      </Item>
    </Menu>
  )
}
export default ContextMenu