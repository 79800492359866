import { useState, useEffect } from "react";

import { toast } from "react-toastify";
import axios from "axios";
import SlidingPane from "react-sliding-pane";
import { Container, Button, Spinner } from "react-bootstrap";
import paymentLogo from "../assests/images/Secure-checkout.jpg";
import { UsePics } from "../State/AppContext";
import AddressForm from "./AddressForm";
import OrderInformation from "./OrderInformation";
import PaymentCompleteModal from "./PaymentCompleteModal";

const Checkout = ({ uploadedPhotos, activeStyle }) => {
  const [about, setAbout] = useState({
    showAddressModal: false,
    uuid: "",
    showSpinner: false,
    showPaymentModal: false,
    resultFromPayment: "",
    paymentSuccess: false,
    address: {
      email: "",
      fullName: "",
      address: "",
      address2: "",
      city: "",
      postCode: "",
      country: "",
    },
    addressUpdated: false,
    showToast: false,
    updateInformation: "",
  });
  const {
    croppedData,
    checkOutDrawer,
    setCheckOutDrawer,
    getButtonName,
    shopifyURL,
    aplyLoader,
    imageMmSize,
    testingImageUpload,
    uploadLoader,
    uploadResponse,
    allUploadRes,
    setAplyLoaderAndCheckOut,
    setShopifyURL,
  } = UsePics();

  const [checkDrawerLoader, setCheckoutDrawerLoader] = useState(false);

  const AddressSubmitted = (value) => {
    let tempAddress = about.address;
    tempAddress.address = value.address;
    tempAddress.address2 = value.address2;
    tempAddress.city = value.city;
    tempAddress.email = value.email;
    tempAddress.fullName = value.fullName;
    tempAddress.postCode = value.postCode;
    tempAddress.country = value.country;
    setAbout({
      address: tempAddress,
      addressUpdated:
        value.address !== "" ||
        value.address !== null ||
        value.address !== undefined,
    });
  };
  function calculatePrice(arr) {
    if (arr.length <= 2) {
        return arr.length === 1 ? 15.99 : 30;
    } else {
        return (39.99 + 10 * (arr.length - 3)).toFixed(2);
    }
}

  const handleDrawerCheckOut = async () => {

      // Send custom event to Google Analytics
      window.gtag('event', 'SecondCheckoutButtonClick', {
        'event_category': 'Second Checkout',
      });

    setCheckoutDrawerLoader(true);
    setCheckOutDrawer(false);
    // const formData = new FormData();
    // const totalPrice = (
    //   croppedData?.length < 3 ? 0.0 : 39.99 + (croppedData?.length - 3) * 10
    // ).toFixed(2);
    const totalPrice = calculatePrice(croppedData);
    // formData.append("Totalprice", totalPrice);
    // formData.append("title", `Sticpics-${getButtonName}-Framed-Print`);
    // formData.append("imageSize", imageMmSize);
    // testingImageUpload &&
    //   testingImageUpload?.forEach((image) => {
    //     formData.append("image", image);
    //   });

    let data = {
      Totalprice: totalPrice,
      allUrl: allUploadRes.map(({ ImageUrl }) => {
        return ImageUrl;
      }),
      title: `Sticpics-${getButtonName}-Framed-Print`,
    };


    const apiUrl = `${process.env.REACT_APP_BASE_URL}createProducts`;
    try {
      const res = await axios.post(apiUrl, data);
      if (res?.status >= 200 && res?.status < 400) {
        const data = res?.data?.data;
        window.open(data?.url, "_self");
      } else {
        const error = res?.data;
        toast.error(error?.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while processing your request.");
    }
  };


  const handleDrwrCheckOut = () => {
    if (shopifyURL) {
      window.open(shopifyURL, "_self");
    } else {
      handleDrawerCheckOut();
    }
  };

  
  useEffect(() => {
    // Track pageview for the checkout page
    window.gtag('config', 'G-KL3EGEBFXF', {
      'page_path': '/SelectPhotos',
    });
  }, []);

  
  return (
    <div>
      {checkDrawerLoader ? (
        <div className="checkout-spinner">
          <Spinner animation="border" role="status" />
          <b>Uploading images to server...</b>
        </div>
      ) : (
        ""
      )}

      <SlidingPane
        isOpen={checkOutDrawer}
        title="Checkout"
        from="right"
        width="400px"
        onRequestClose={() => {
          setCheckOutDrawer(false);
          setAplyLoaderAndCheckOut(false);
          setShopifyURL("");
        }}
      >
        {uploadLoader ? (
          <div className="drawer-loader">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          ""
        )}
        <div>
          <Container>
            <AddressForm
              AddressSubmitted={AddressSubmitted}
              showModal={about.showAddressModal}
              closeModal={() => setAbout({ showAddressModal: false })}
            />
            <hr />
            <OrderInformation
              activeStyle={activeStyle}
              uploadedPhotos={uploadedPhotos}
            />
            <hr />
            <Button disabled={aplyLoader} onClick={() => handleDrwrCheckOut()}>
              Checkout
            </Button>
            <hr />
            <div className="payment-logo">
              <img src={paymentLogo} alt="payment-logo"></img>
            </div>
            {/* <div>
              **Minimum order of 3 tiles for €39.99. Each additional tile is €10
              there after
            </div> */}
          </Container>
        </div>
      </SlidingPane>
      <PaymentCompleteModal
        information={about.updateInformation}
        show={about.showPaymentModal}
        success={about.paymentSuccess}
        hidePaymentCompleteModal={() => setAbout({ showPaymentModal: false })}
        uploadedPhotos={uploadedPhotos}
      />
    </div>
  );
};
export default Checkout;
