import React, { useState, useEffect } from 'react';

import cookie from 'react-cookies';
import { Modal } from 'react-bootstrap';
import { Form, Input, Submit } from 'react-smart-form';
import { required, email } from 'react-smart-form/validators';

const AddressForm = ({ showModal, closeModal, AddressSubmitted }) => {


  const [details, setDetails] = useState({
    showModal: false,
    email: "",
    address: "",
    address2: "",
    city: "",
    postCode: "",
    country: ""
  })
  
  useEffect(() => {
    let email = IsNullOrUndefined(cookie.load("email"));
    let fullName = IsNullOrUndefined(cookie.load("fullName"));
    let address = IsNullOrUndefined(cookie.load("address"));
    let address2 = IsNullOrUndefined(cookie.load("address2"));
    let city = IsNullOrUndefined(cookie.load("city"));
    let postCode = IsNullOrUndefined(cookie.load("postcode"));
    let country = IsNullOrUndefined(cookie.load("country"));


    setDetails({
      email: email,
      fullName: fullName,
      address: address,
      address2: address2,
      city: city,
      postCode: postCode,
      country: country
    });
  }, [])
  const IsNullOrUndefined = (string) => {
    if (string === null || string === undefined) {
      return "";
    }
    return string
  }
  const handleSubmit = (values) => {
    // Maybe some validation
    closeModal();

    cookie.save("email", values?.email, { path: '/' })
    cookie.save("fullName", values?.fullName, { path: '/' })
    cookie.save("address", values?.address, { path: '/' })
    cookie.save("address2", values?.address2, { path: '/' })
    cookie.save("city", values?.city, { path: '/' })
    cookie.save("postcode", values?.postCode, { path: '/' })
    cookie.save("country", values?.country, { path: '/' })
    setDetails({
      email: values?.email,
      fullName: values?.fullName,
      address: values?.address,
      address2: values?.address2,
      city: values?.city,
      postCode: values?.postCode,
      country: values?.country
    });
  }
  return (
    <div>
      <Modal show={showModal || details.showModal} o>
        <Modal.Header >
          <button className="exitButton" onClick={closeModal}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <Modal.Title>Address Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainContainer">
            <div className="formContainer">
              <Form onSubmit={() => {
                handleSubmit(); setDetails({
                  showModal: false
                })
              }} >
                <Input name="email" label="Email" validators={[required('Email is required'), email('This is not a valid email.')]} />
                <Input name="fullName" label="Full Name" validators={[required('Full name is required')]} />
                <Input name="address" label="Address" validators={[required('Address is required')]} />
                <Input name="address2" label="Address 2" />
                <Input name="city" label="City" validators={[required('City/County is required')]} />
                <Input name="postCode" label="Post Code" validators={[required('Post code is required')]} />
                <Input name="country" label="Country" validators={[required('Country is required')]} />
                <Submit>
                  Submit
                </Submit>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddressForm