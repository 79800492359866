import React from 'react';

import { Modal, Button, ProgressBar } from 'react-bootstrap';

const PaymentCompleteModal = ({ uploadedPhotos, information, show, success, hidePaymentCompleteModal }) => {

  // async function sendMissingInformation() {
  //   if (uploadedPhotos.every(item => item.hasRecievedFileStackUrl)) {
  //     var info = information;
  //     // maybe assemble a url which includes a crop
  //     const response = await fetch('https://u5xi7cvkj9.execute-api.eu-west-1.amazonaws.com/dev/updateCheckoutInfo', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(info)
  //     })
  //     return true;
  //   }
  // };
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{success ? "Payment Successful" : "Payment Failed"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please wait While the Images Are Uploaded
        {uploadedPhotos?.map(p => {
          return (<div>
            <ProgressBar animated now={p?.uploadPercent} label={p?.hasRecievedFileStackUrl ? "Complete" : `${p?.name} - % ${p?.uploadPercent}`} />
            <br />
          </div>)
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => hidePaymentCompleteModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default PaymentCompleteModal