import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Navbar, Nav, DropdownButton, Dropdown } from "react-bootstrap";

import ModalProvider from "./ModalProvider";

const Header = () => {
  const navigate = useNavigate();
  const [headData, setheadData] = useState({
    showModal: false,
    modalToShow: null,
  });
  const processDropDownClick = (option) => {
    setheadData({
      showModal: true,
      modalToShow: option,
    });
  };

  return (
    <div>
      <Navbar bg="light">
        <Nav className="container-fluid">
          <Link to="/" className="back-button btn btn-light">
            <i className="fa fa-angle-left fa-2x" />
          </Link>
          <div className="imageContainer">
            <img
              className="mainLogo"
              src="./assets/Photos/SticPicsLogo1.png"
              alt=""
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Nav.Item id="menuPopOver" className="ml-auto">
            <DropdownButton
              variant="light"
              id="dropdown-menu-align-right"
              title={
                <i className="fa fa-bars blue fa-1x" aria-hidden="true"></i>
              }
            >
              <Dropdown.Item onClick={() => processDropDownClick("FAQ")}>
                {" "}
                Frequent Questions
              </Dropdown.Item>
              <Dropdown.Item onClick={() => processDropDownClick("ContactUs")}>
                {" "}
                Contact us{" "}
              </Dropdown.Item>
            </DropdownButton>
          </Nav.Item>
        </Nav>
      </Navbar>
      <ModalProvider
        modalToShow={headData.modalToShow}
        showModal={headData.showModal}
        closeModal={() => setheadData({ showModal: false })}
      />
    </div>
  );
};

export default Header;
