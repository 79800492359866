import { Route, Routes, HashRouter } from 'react-router-dom';

import HomePage2 from './HomePage2';
import SelectPhoto from './SelectPhoto';

const Main = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<HomePage2 />} />
                <Route path="/SelectPhotos" element={<SelectPhoto />} />
            </Routes>
        </HashRouter>
    )
};

export default Main;